import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { BiTimeFive } from "react-icons/bi";
import { toPlainText } from "../lib/helpers";

import styled, { css } from 'styled-components';
import TextTruncate from 'react-text-truncate';

function BlogPostPreview(props) {
  return (
    <Link to={getBlogUrl(props.slug.current)}>
      <Article>
        <Card>
          {props.mainImage && props.mainImage.asset && (
            <CardImage
              alt={props.mainImage.alt}
              src={imageUrlFor(props.mainImage).url()}
            />
          )}
          <CardBody>
            {props.categories && props.categories.map(category => (
              <Tag key={category._id}><span>{category.title}</span></Tag>
            ))}
            <h3>{props.title}</h3>
            <TextTruncate
              line={2}
              element="p"
              truncateText="…"
              text={toPlainText(props._rawExcerpt)}
            />
            <Info>
              <p />
              <ReadTime>
                <BiTimeFive />
                {format(props.publishedAt, "MMMM Do, YYYY")}
              </ReadTime>
            </Info>
          </CardBody>
        </Card>
      </Article>
    </Link>
  );
}

export default BlogPostPreview;

const Article = styled.article`
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s ease 0s;
  margin: 0 0 16px 0;
`;

const Card = styled.div`
  display: flex;
  height: 100%;
  padding: 16px;
`;

const CardImage = styled.img`
  height: 150px;
  width: 150px;
  min-width: 150px;
  object-fit: cover;
  border-radius: 7px;

  @media (min-width: 800px) {
    height: 240px;
    width: 240px;
    min-width: 240px;
  }
`;

const Tag = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 4px;
  margin: -4px;
  border-radius: 7px;
  background: white;

  span {
    display: flex;
    align-content: center;
    justify-content: center;
    color: #181917;
    font-size: 12px;
    font-weight: bolder;
    padding: 2px 7px 5px;
    border-radius: 5px;
    background: #00fed0;

    @media (min-width: 600px) {
      font-size: 14px;
      padding: 4px 8px 8px;
    }
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 0 0 0 16px;

  @media (min-width: 800px) {
    height: auto;
    padding: 16px 16px 0;
  }

  h3 {
    font-size: 16px;
    margin: 16px 0 0;
    line-height: 1.3;

    @media (min-width: 600px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    margin: 8px 0 0;

    @media (min-width: 600px) {
      font-size: 18px;
      margin: 16px 0;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 0;
`;

const ReadTime = styled.p`
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px -2px 0;
  }
`;

const ButtonList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    list-style: none;
`;

const ButtonItem = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    background: white;
    border-radius: 4px;
    margin-bottom: 8px;
    overflow: hidden;

    &:before {
        position: absolute;
        content: '';
        width: 4px;
        height: 100%;
        background: #293032;
        transition: all 0.2s;
    }

    &:hover {
        &:before {
            width: 6px;
            background: #8dbe23;
        }
    }

    button {
        font-size: 20px;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 13px 0 16px 24px;
        font-weight: 500;
        background: none;
        border: none;
        cursor: pointer;
    }

    ${({active}) => active && css`
      &:before {
        width: 6px;
        background: #8dbe23;
      }

      button {
        color: #14A58A;
      }
    `};
`;

const CtaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: ${props => props.background};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease 0s;
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 60%;
    padding: 32px;
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const IconWrapper = styled.div`
  padding: 16px 0;

  @media (min-width: 1024px) {
    padding: 32px 0;
  }
`;

const Icon = styled.div`
  padding: 0;
  text-align: center;
  
  svg {
    font-size: 32px;
    color: #bbbbbb;
  }

  @media (min-width: 1024px) {
    text-align: left;
    padding: 16px 0;

    svg {
      font-size: 42px;
    }
  }
`;

const IconHeader = styled.h4`
  font-size: 22px !important;
  font-weight: bold;
  padding: 0px 0 16px;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 24px;
  }
`;

const IconContent = styled.p`
  font-size: 16px !important;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1024px) {
    width: 30%;
    margin-left: 32px;
    margin-top: 0;
    position: sticky;
    top: 120px;
  }

  ${CtaBlock} {
    ${CtaContent} {
      max-width: 100%;

      h2 {
        font-size: 20px;

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }

      ${IconWrapper} {
        padding: 0;

        ${Icon} {
          padding: 0;
          margin: -10px 0 4px -3px;
        }
      }
    }
  }
`;

const Map = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const MapIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;